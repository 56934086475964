import { toast } from 'sonner';

const chars = {
  '/': '_',
  '{': '_',
  '}': '_',
  '|': '_',
  '\\': '_',
  '^': '_',
  '[': '_',
  ']': '_',
  '`': '_',
  ';': '_',
  '?': '_',
  ':': '_',
  '@': '_',
  '&': '_',
  '=': '_',
  '+': '_',
  $: '_',
  ',': '_',
  '<': '_',
  '>': '_',
  '#': '_',
  '%': '_',
};

export function ValidateSignUp(form) {
  const errors = {};
  const patternEmail = /^([^\s@]+@[^\s@]+\.[^\s@]+$)$/i;
  const isMatch = form.password === form.matchpassword;
  if (!form.username) {
    toast.warning('Nutzername ist pflicht!', { duration: 4000 });
    errors.username = 'Nutzername ist pflicht!';
  } else if (form.username) {
    let replaced = form.username.replace(/[#+<>%,$+=&@:?;\`\]\[\^\\|}{/]/g, (m) => chars[m]);
    if (form.username !== replaced) {
      toast.warning('Nutzername nicht valide!', { duration: 4000 });
      errors.username = 'Nutzername nicht valide!';
    }
    if (form.username.length > 20 || form.username.length < 4) {
      toast.warning('Nutzername muss 4-20 Zeichen lang sein!', { duration: 4000 });
      errors.username = 'Nutzername muss 4-20 Zeichen lang sein!';
    }
  }
  if (!form.email) {
    toast.warning('E-Mail-Adresse ist pflicht!', { duration: 4000 });
    errors.email = 'E-Mail-Adresse ist pflicht!';
  } else if (!patternEmail.test(form.email)) {
    toast.warning('Email Format nicht valide!', { duration: 4000 });
    errors.email = 'Email Format nicht valide!';
  }
  // if (!form.firstName) {
  //   errors.firstName = 'Please enter your first name';
  // }
  // if (!form.lastName) {
  //   errors.lastName = 'Please enter your last name';
  // }
  if (!form.password) {
    toast.warning('Passwort eingeben!', { duration: 4000 });
    errors.password = 'Passwort eingeben"';
  } else if (form.password.length < 8 || form.password.length > 20) {
    toast.warning('Inkorrektes Format, 8-20 Zeichenlänge benötigt!', { duration: 4000 });
    errors.password = 'Inkorrektes Format, 8-20 Zeichenlänge benötigt!';
  }
  if (!form.matchpassword) {
    toast.warning('Passwort erneut eingeben!', { duration: 4000 });
    errors.matchpassword = 'Passwort erneut eingeben!';
  } else if (!isMatch) {
    toast.warning('Passwort nicht identisch!', { duration: 4000 });
    errors.matchpassword = 'Passwort nicht identisch!';
  }
  if (!form.privacy) {
    toast.warning('Bitte AGB lesen und akzetieren!', { duration: 4000 });
    errors.privacy = 'Bitte AGBs sowie Datenschutz lesen und akzeptieren!';
  }

  return errors;
}

export function ValidateEditUser(form) {
  const errors = {};
  const patternEmail = /^([^\s@]+@[^\s@]+\.[^\s@]+$)$/i;

  if (!form.firstName) {
    toast.warning('Gib zuerst deinen Vornamen an!', { duration: 4000 });
    errors.firstName = 'Vorname ist ein Pflichtfeld!';
  }
  if (!form.profileText) {
    toast.warning('Füge zuerst eine Profilbeschreibung hinzu!', { duration: 4000 });

    errors.profileText = 'Bitte füge eine Profilbeschreibung hinzu';
  } else if (form.profileText.length > 250 || form.profileText?.length < 25) {
    toast.warning('Profilbeschreibung: 25-250 Zeichen!', { duration: 4000 });

    errors.profileText = '25-250 Zeichen!';
  }
  if (form.slogan) {
    if (form.slogan.length > 70) {
      toast.warning('slogan: Max. 70 Zeichen!', { duration: 4000 });

      errors.slogan = 'Max. 70 Zeichen!';
    }
  }
  if (form.firstName?.length > 25 || form.username?.length < 2) {
    toast.warning('Vorname und Nutzername: 2-25 Zeichen!', { duration: 4000 });

    errors.firstName = '2-25 Zeichen!';
  }
  if (form.lastName?.length > 25 || form.username?.length < 2) {
    toast.warning('Nachname und Nutzername: 2-25 Zeichen!', { duration: 4000 });

    errors.lastName = '2-25 Zeichen!';
  }
  if (!form.username) {
    toast.warning('Nutzername fehlt!', { duration: 4000 });

    errors.username = 'Nutzername fehlt!';
  } else if (form.username) {
    let replaced = form.username.replace(/[#+<>%,$+=&@:?;\`\]\[\^\\|}{/]/g, (m) => chars[m]);
    if (form.username !== replaced) {
      errors.username = 'Nutzername nicht valide!';
    }
    if (form.username?.length > 20 || form.username?.length < 4) {
      errors.username = '4-20 Zeichen!';
    }
  }

  if (!form.email) {
    toast.warning('E-Mail-Adresse ist pflicht!', { duration: 4000 });

    errors.email = 'E-Mail-Adresse ist pflicht!';
  } else if (!patternEmail.test(form.email)) {
    toast.warning('E-Mail Format ist nicht valide!', { duration: 4000 });

    errors.email = 'E-Mail Format ist nicht valide!';
  }
  if (
    form.socials.facebook?.length >= 300 ||
    form.socials.instagram?.length >= 300 ||
    form.socials.youtube?.length >= 300 ||
    form.socials.twitter?.length >= 300 ||
    form.socials.spotify?.length >= 300 ||
    form.socials.soundcloud?.length >= 300 ||
    form.socials.linkedin >= 300 ||
    form.socials.website >= 300
  ) {
    toast.warning('URL ist invalide! Max 300 Zeichen und mit https:// beginnen!', { duration: 4000 });

    errors.socials = 'URL ist invalide! Max 300 Zeichen und mit https:// beginnen';
  }
  return errors;
}

// recording studio
export function ValidateCreateStudioListing(form) {
  const errors = {};
 
  if (!form.studioName) {
    errors.studioName = 'Dein Studioname fehlt!';
  } else if (form.studioName?.length > 40 || form.studioName?.length < 5) {
    errors.studioName = '5-40 Zeichen länge für deinen Studionamen benötigt!';
  }

  if (!form.studiotype) {
    errors.studiotype = "Just don't. Instead be a part of the team!";
  }
  if (form.studioLanguages?.length === 0) {
    errors.studioLanguages = 'Füge mindestens eine Sprache hinzu!';
  } else if (form.studioLanguages?.length >= 6) {
    errors.studioLanguages = 'Zuviele Sprachen ausgewählt. Maximal 5 stück!';
  }

  if (form.locationFeatures?.length === 0) {
    errors.locationFeatures = 'Wähle mindestens 1 Merkmal aus!';
  }

  if (!form.studioLocation?.address) {
    errors.studioLocation = 'Bitte gib deine volle Adresse ein und wähle dann aus den Vorschlägen aus.';
  } else if (!form.studioLocation.geolocation) {
    errors.studioLocation = 'Keine Koordinaten gefunden. Bitte vollständige Adresse mit Hausnummer auswählen.';
  }  
  return errors;
}

// other profile
export function ValidateCreateOtherProfile(form) {
  const errors = {};
  const patternLocation = /^([a-zA-Z-0-9,äßöü])([a-zA-Z-0-9,äßöü\s]){4,160}$/i;

  if (!form.name) {
    errors.name = 'Dein Name fehlt!';
  } else if (form.name > 25 || form.name < 2) {
    errors.name = '2-25 Zeichen!';
  }
  if (form?.surname) {
    if (form?.surname > 30 || form?.surname < 2) {
      errors.surname = 'Eingabe ist nicht valide! 2-30 Zeichen';
    }
  }
  if (!form.languages?.length > 0) {
    errors.languages = 'Wähle mindestens eine Sprache aus.';
  }

  if (!form?.age) {
    errors.age = 'Dein Alter fehlt!';
  }
  if (form.about?.length) {
    if (form.about.length > 1200) {
      errors.about = 'Text ist zu lang!';
    } else if (form.about.length < 120) {
      errors.about = 'Text ist zu kurz';
    }
  }

  if (
    form.socials.spotify?.length >= 300 ||
    form?.socials?.soundcloud?.length >= 300 ||
    form.socials.youtube?.length >= 300 ||
    form.socials.facebook?.length >= 300 ||
    form.socials.instagram?.length >= 300 ||
    form.socials.twitter?.length >= 300 ||
    form.socials.pinterest?.length >= 300 ||
    form.socials.linkedin >= 300
  ) {
    errors.socials = 'Max 300 Zeichen.';
  }

  if (!form.location.city) {
    errors.location = 'Bitte gib deine volle Adresse ein und wähle dann aus den Vorschlägen eine Adresse aus.';
  } else if (!form.location.geolocation) {
    errors.location = 'Keine Koordinaten gefunden. Bitte vollständige Adresse mit Hausnummer auswählen.';
  } else if (!patternLocation.test(form.location.address)) {
    errors.location = 'Your input is not valid';
  }
  return errors;
}

// local services
export function ValidateCreateStudioServiceListing(form, checked) {
  // For creating studio service ad
  const errors = {};
  const regexEquipment = /^([a-zA-Z-])([a-zA-Z-0-9!äöü,()!-_\s]){0,299}$/i;
  const url = /^((http|https):\/\/)/;

  if (form.service.length === 0) {
    errors.service = 'Bitte wähle eine Service Kategorie';
  }

  if (!form.listingTitle) {
    errors.listingTitle = 'Bitte einen Titel schreiben - 80 Zeichen max';
  } else if (form.listingTitle.length < 25 || form.listingTitle.length > 80) {
    errors.listingTitle = 'Nur 25-80 Zeichen erlaubt!';
  }

  if (!form.description) {
    errors.description = 'Bitte eine Beschreibung deiner Dienstleistung schreiben.';
  } else if (form.description.length <= 99) {
    errors.description = 'Beschreibung ist zu kurz. Min 100 Zeichen.';
  } else if (form.description.length >= 1501) {
    errors.description = 'Die Beschreibung ist zu lang. Max 1500 Zeichen.';
  }
  // else if (!regexDescription.test(form.description)) {
  //   errors.description = 'Nur 100-1500 Zeichen und (a-z, A-Z, 0-9, ! äöü ,()!-_) erlaubt!';
  // }

  if (!form.maxGuests) {
    errors.maxGuests = 'Schreibe deine Kapazität für Gäste auf. Eine Zahl zwischen 1 und 20.';
  } else if (form.maxGuests >= 21 || form.maxGuests <= 0) {
    errors.maxGuests = 'Nur eine Zahl zwischen 1 und 20.';
  }

  if (form.spotify?.length >= 299) {
    errors.spotify = 'Spotify Link fehlerhaft';
  } else if (form.spotify?.length >= 2 && !url.test(form.spotify)) {
    errors.spotify = 'Spotify Url fehlerhaft';
  }

  if (form.soundcloud?.length >= 299) {
    errors.soundcloud = 'Soundcloud Link fehlerhaft';
  } else if (form.soundcloud?.length >= 2 && !url.test(form.soundcloud)) {
    errors.spotify = 'Soundcloud Url fehlerhaft';
  }

  if (form?.equipment) {
    if (!regexEquipment.test(form.equipment)) {
      errors.equipment = 'Maximal 300 Zeichen und nur (a-z, A-Z, 0-9, ! äöü ,()!-_) erlaubt!';
    }
  }

  if (!form.subInformations) {
    errors.subInformations = 'Bitte wähle eine Währung aus';
  }
  if (checked?.otherImagesPreview?.length === 0 || checked?.otherImagesPreview?.length >= 6) {
    errors.image = 'Füge mindestens 1 und maximal 5 Bilder hinzu.';
  }

  if (!form.audioengineer.status) {
    errors.audioengineer = 'Wähle eine Option für die Verfügbarkeit eines Tontechnikers in diesem Service!';
  } else if (form.audioengineer.status === 'Custom' && Object.keys(form.audioengineer).length - 1 === 0) {
    {
      errors.audioengineer = 'Fülle mindestens ein Preis-Eingabefeld aus.';
    }
  } else if (form.audioengineer.status === 'Custom' && Object.keys(form.audioengineer).length - 1 > 0) {
    if (
      form.audioengineer.audioPricingHour &&
      (form.audioengineer.audioPricingHour < 5 ||
        form.audioengineer.audioPricingHour > 9999 ||
        form.audioengineer.audioPricingHour % 5 !== 0)
    ) {
      errors.pricing = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form.audioengineer.audioPricingDay &&
      (form.audioengineer.audioPricingDay < 5 ||
        form.audioengineer.audioPricingDay > 9999 ||
        form.audioengineer.audioPricingDay % 5 !== 0)
    ) {
      errors.audioengineer = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form.audioengineer.audioPricingWeekend &&
      (form.audioengineer.audioPricingWeekend < 5 ||
        form.audioengineer.audioPricingWeekend > 9999 ||
        form.audioengineer.audioPricingWeekend % 5 !== 0)
    ) {
      errors.audioengineer = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form.audioengineer.audioPricingWeek &&
      (form.audioengineer.audioPricingWeek < 5 ||
        form.audioengineer.audioPricingWeek > 9999 ||
        form.audioengineer.audioPricingWeek % 5 !== 0)
    ) {
      errors.audioengineer = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form.audioengineer.audioPricingMonth &&
      (form.audioengineer.audioPricingMonth < 5 ||
        form.audioengineer.audioPricingMonth > 9999 ||
        form.audioengineer.audioPricingMonth % 5 !== 0)
    ) {
      errors.audioengineer = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
  }

  if (Object.keys(form.pricing).length === 0 && form.pricing.constructor === Object) {
    errors.pricing = 'Bitte Fülle mindestens ein Preis Eingabefeld aus!';
  } else if (form.pricing && Object.keys(form?.pricing).length - 1 > 0) {
    if (
      form?.pricing?.pricingHour &&
      (form?.pricing?.pricingHour < 5 || form?.pricing?.pricingHour > 9999 || form?.pricing?.pricingHour % 5 !== 0)
    ) {
      errors.pricing = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form?.pricing?.pricingDay &&
      (form?.pricing?.pricingDay < 5 || form?.pricing?.pricingDay > 9999 || form?.pricing?.pricingDay % 5 !== 0)
    ) {
      errors.audioengineer = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form?.pricing?.pricingWeekend &&
      (form?.pricing?.pricingWeekend < 5 ||
        form?.pricing?.pricingWeekend > 9999 ||
        form?.pricing?.pricingWeekend % 5 !== 0)
    ) {
      errors.pricing = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form?.pricing?.pricingWeek &&
      (form?.pricing?.pricingWeek < 5 || form?.pricing?.pricingWeek > 9999 || form?.pricing?.pricingWeek % 5 !== 0)
    ) {
      errors.pricing = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
    if (
      form?.pricing?.pricingMonth &&
      (form?.pricing?.pricingMonth < 5 || form?.pricing?.pricingMonth > 9999 || form?.pricing?.pricingMonth % 5 !== 0)
    ) {
      errors.pricing = ' Mindestens 5€ maximal 9.995€. Immer in 5€ Schritten';
    }
  }

  if (!form.pledge) {
    errors.pledge = 'Bitte entscheide ob du eine Kaution verlangst und wie hoch diese ist.';
  }

  if (!form.workingHours.status) {
    errors.workingHours = 'Bitte wähle deine Öffnungszeiten aus';
  } else {
    if (form.workingHours.status === 'custom') {
      let flag = false;
      form.workingHours.data.map((day) => {
        if (day.times.length > 0) flag = true;
      });
      if (!flag) {
        errors.workingHours = 'Füge mindestens einen Wert für die Öffnungszeit hinzu. ';
      }
    }
  }

  if(form?.differentLocation?.status == "true"){
  if (!form.differentLocation?.location?.address) {
    errors.differentLocation = 'Bitte gib deine vollständige Adresse ein und wähle dann aus den Vorschlägen aus.';
  } else if (!form.differentLocation?.location?.geolocation) {
    errors.differentLocation = 'Keine Koordinaten gefunden. Bitte vollständige Adresse mit Hausnummer auswählen.';
  }  
  } else{
    delete errors.differentLocation;
  }

  return errors;
}

// online services
export function ValidateCreateOnlineServicesListing(data, step, section) {
  const errors = {};

  if (step == 'overview') {
    if (data?.title?.trim()?.length < 15) {
      errors.title = 'Schreibe einen Titel mit 15 - 80 Zeichenlänge.';
    }
    if (!data?.category?._id) {
      errors.category = 'Bitte wähle eine Kategorie aus.';
    }
    if (data?.category?.subCategories?.length && !data?.subcategory?._id) {
      errors.category = 'Bitte wähle Unterkategorie aus.';
    }
  }
  if (step == 'pricing') {
    if (section == 'packages') {
      let validation = true;
      data?.map((item) => {
        validation =
          validation &&
          item?.name?.trim()?.length <= 35 &&
          item?.name?.trim()?.length >= 1 &&
          item?.description?.length >= 1 &&
          item?.description?.length <= 150 &&
          item?.deliverytime &&
          item?.revision &&
          item?.price &&
          item?.price >= 5 &&
          item?.price <= 9999 &&
          item?.price % 5 == 0;
      });
      if (!validation) {
        errors.packages = 'Behebe den Fehler im Bereich für Pakete.';
      }
    }
    if (section == 'fastDelivery') {
      let validation = true;
      data?.map((item) => {
        validation =
          validation &&
          !(
            (!item?.deliverytime && item?.price) ||
            (item?.deliverytime && (!item?.price || item?.price < 5 || item?.price % 5 !== 0))
          );
      });
      if (!validation) {
        errors.fastdelivery = 'Behebe den Fehler im Bereich "Extra schnelle Lieferung".';
      }
    }
    if (section == 'additionalRevision') {
      let validation = true;
      validation =
        validation &&
        !(
          (!data?.deliverytime && data?.price) ||
          (data?.deliverytime && (!data?.price || data?.price < 5 || data?.price % 5 !== 0))
        );

      if (!validation) {
        errors.additionalrevision = 'Behebe den Fehler im Bereich "Zusätzliche Revisionen".';
      }
    }
    if (section == 'digitalExtra') {
      let validation = true;
      data?.map((item) => {
        validation =
          validation &&
          !(
            item?.isChecked &&
            !(
              item?.title &&
              item?.title?.length >= 3 &&
              item?.title?.length <= 20 &&
              item?.description &&
              item?.description?.length >= 3 &&
              item?.description?.length <= 100 &&
              item?.price &&
              item?.price >= 5 &&
              item?.price % 5 == 0 &&
              item?.deliverytime
            )
          );
      });
      if (!validation) {
        errors.digitalExtra = 'Behebe den Fehler im Bereich "Extra Zusatzleistungen hinzufügen".';
      }
    }
  }

  return errors;
}

// admin dashboard
export function ValidateCreateStudioService(form) {
  // For create studioservice category
  const errors = {};
  const serviceDescription = /^([a-zA-Z-])([a-zA-Z-0-9,.!ä?&/öü\s]){9,149}$/i;
  if (!form.description) {
    errors.description = 'A Servicedescription is required!';
  } else if (!serviceDescription.test(form.description)) {
    errors.description = 'Your service description input is not valid!';
  }

  return errors;
}
